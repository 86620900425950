#grad {
    background-image: linear-gradient(to left, #09c6f9,#045de9);
    padding: 5rem 2.5rem;
    align-content: center;
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #485fc7;
    right: 1.125em;
    z-index: 0;
}